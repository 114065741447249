import React, { useEffect, useState } from "react";
import styles from "./GradedEssay.module.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Sidebar from "../components/Sidebar";
import PDFViewer from "../components/graded_essay_page/PDFViewer";
import DownloadButtons from "../components/graded_essay_page/DownloadButtons";
import DonutChart from "../components/graded_essay_page/DonutChart";
import Parameters from "../components/graded_essay_page/Parameters";
import Carousel from "../components/graded_essay_page/Carousel";
import middleDivider from "../assets/graded-essay-page-middle-divider.svg";
import { useParams } from "react-router-dom";
import { documentDetail, updateScoresDocument } from "../API/api";
import { filterAIOutput } from "../utils/filterAIOutputt";
import PdfEditorFabric from "../components/graded_essay_page/PDFEditorFabric";
import EditModal from "../components/graded_essay_page/EditModal";

function GradedEssay() {
  const { id } = useParams();
  const [editPdf, setEditPdf] = useState(false);
  const [document, setDocument] = useState();
  const [expand, setExpand] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [contentScore, setContentScore] = useState(0);
  const [languageScore, setLanguageScore] = useState(0);
  const [maxPoints, setMaxPoints] = useState(0);
  const [showEdit, setShowEdit] = useState(false);

  const handleDocumentDetail = async () => {
    try {
      const res = await documentDetail(id);
      setDocument(res);
      // Set the initial scores after fetching the document
      const totalScoreEntry = filterAIOutput(res?.processedOutputAI, "Total Score")[1];
      const contentScoreEntry = filterAIOutput(res?.processedOutputAI, "Content Score")[1];
      const languageScoreEntry = filterAIOutput(res?.processedOutputAI, "Language Score")[1];
      setTotalScore(parseFloat(totalScoreEntry));
      setContentScore(parseFloat(contentScoreEntry));
      setLanguageScore(parseFloat(languageScoreEntry));
      const points = parseInt(res?.pointSystem.slice(0, 2));
      setMaxPoints(points / 2);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitScores = async (newContentScore, newLanguageScore) => {
    try {
      // Set the updated scores in the state
      setContentScore(newContentScore);
      setLanguageScore(newLanguageScore);
      setTotalScore(newContentScore + newLanguageScore);
  
      // Prepare the data to send to the backend
      const payload = {
        documentId: document._id, // Use the document ID
        contentScore: newContentScore,
        languageScore: newLanguageScore,
      };
  
      // Call the API to update the document in the backend
      await updateScoresDocument(document._id, payload);
  
      setShowEdit(false); // Close the modal after submission
      alert("Scores updated successfully");
    } catch (error) {
      console.error("Error updating document:", error);
      alert("Failed to update scores. Please try again.");
    }
  };

  useEffect(() => {
    if (id && !editPdf) handleDocumentDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPdf]);

  const onEditPdf = () => {
    setEditPdf(true);
  };

  const onEditMarks = () => { 
    setShowEdit(true);
  }

  return (
    <main className={styles.container}>
      <Sidebar />
      {!expand ? (
        <div className={styles.contentWrapper}>
          {document && (
            <>
              <section className={styles.pdfColumn}>
                {!editPdf && (
                  <PDFViewer data={document} handleEditPdf={onEditPdf} />
                )}
                {editPdf && (
                  <PdfEditorFabric
                    data={document}
                    setEditPdf={setEditPdf}
                    setExpand={setExpand}
                  />
                )}
              </section>
              <img src={middleDivider} alt="" className={styles.divider} />
              <section className={styles.mainContent}>
                <DownloadButtons data={document} onEditMarks={onEditMarks} />
                <DonutChart data={document} totalScore={totalScore} contentScore={contentScore} languageScore={languageScore}/>
                <Carousel data={document} />
                <Parameters data={document} />
              </section>
            </>
          )}
        </div>
      ) : (
        <div className={styles.editContentWrapper}>
          <PdfEditorFabric
            data={document}
            setEditPdf={setEditPdf}
            setExpand={setExpand}
            expand={expand}
          />
        </div>
      )}
      
      {showEdit && (
        <EditModal
          contentScore={contentScore}
          languageScore={languageScore}
          maxPoints={maxPoints}
          onSubmit={handleSubmitScores}
          onClose={() => setShowEdit(false)} // Close modal without saving
        />
      )}
    </main>
  );
}

export default GradedEssay;
