import React from 'react';
import styles from '../../pages/GradedEssay.module.css';
import file_download_icon from '../../assets/file-download.svg';

function DownloadButtons(props) {
  const {data, onEditMarks} = props;

  const handleDownloadPDF = () => {
    // Replace with the actual URL for the PDF file
    const url =  data.fileUrl;
    window.open(url, '_blank');
  };

  return (
    <section className={styles.gradeSection}>
      <div className={styles.downloadButtons}>
        <button onClick={onEditMarks} className={styles.downloadButton}>
          Edit marks
        </button>
        <button onClick={handleDownloadPDF} className={styles.downloadButton}>
          <img src={file_download_icon} alt="Download PDF" />
          PDF
        </button>
      </div>
    </section>
  );
}

export default DownloadButtons;