/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "../../pages/GradedEssay.module.css";
import leftArrowIcon from "../../assets/left-arrow-with-circle.svg";
import rightArrowIcon from "../../assets/right-arrow-with-circle.svg";
import { filterAIOutput } from "../../utils/filterAIOutputt";
import { updateDocument } from "../../API/api";

const Carousel = (props) => {
  const { data } = props;

  const summaryOutput = filterAIOutput(data?.processedOutputAI, "Summary");
  const feedbackOutput = filterAIOutput(data?.processedOutputAI, "Feedback");
  const strengthOutput = filterAIOutput(data?.processedOutputAI, "Strength");
  const weaknessOutput = filterAIOutput(data?.processedOutputAI, "Weakness");
  const errorOutput = filterAIOutput(data?.processedOutputAI, "Errors");
  const revisionOutput = filterAIOutput(data?.processedOutputAI, "Revision");


  const formatText = (text) => {
    return text.replace(/<br>/g, "\n");
  };

  const summary = "Summary: \n\n" + (summaryOutput[1] ? formatText(summaryOutput[1]) : "None");
  const feedback = "Feedback: \n" + (feedbackOutput[1] ? formatText(feedbackOutput[1]) : "None");
  const strength = "Strength: \n" + (strengthOutput[1] ? formatText(strengthOutput[1]) : "None");
  const weakness = "Weakness: \n" + (weaknessOutput[1] ? formatText(weaknessOutput[1]) : "None");
  const error = "Errors: \n" + (errorOutput[1] ? formatText(errorOutput[1]) : "None");
  const revision = "Revision: \n" + (revisionOutput[1] ? formatText(revisionOutput[1]) : "None");

  // const summary = "Summary: " + (summaryOutput[1] || "None");
  // const feedback = "Feedback: " + (feedbackOutput[1] || "None");
  // const strength = "Strength: " + (strengthOutput[1] || "None");
  // const weakness = "Weakness: " + (weaknessOutput[1] || "None");
  // const error = "Errors: " + (errorOutput[1] || "None");
  // const revision = "Revision: " + (revisionOutput[1] || "None");

  const items = [summary, feedback, strength, weakness, error, revision];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentText, setCurrentText] = useState(items[0]);

  useEffect(() => {
    setCurrentText(items[currentIndex]);
  }, [currentIndex]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    setIsEditing(false);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length
    );
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const findKey = () => {
    let key = "";
    switch (currentIndex) {
      case 0:
        key = "Summary";
        break;
      case 1:
        key = "Feedback";
        break;
      case 2:
        key = "Strength";
        break;
      case 3:
        key = "Weakness";
        break;
      case 4:
        key = "Errors";
        break;
      case 5:
        key = "Revision";
        break;
      default:
        break;
    }

    return key;
  }

  const handleTextChange = (e) => {
    setCurrentText(e.target.value);
  };

  const handleBlur = async () => {
    setIsEditing(false);
    // Optionally update the items array with the new text

    let key = findKey();

    // const currentTextValue =
    //   currentText.indexOf(key + ":") === 0
    //     ? currentText.slice(key.length + 2)
    //     : currentText;

    // Check if the text already starts with the key (e.g., "Summary: ")
    const currentTextValue = currentText.startsWith(`${key}:`)
      ? currentText // If the title is already there, keep it as is
      : `${key}: ${currentText}`; // Otherwise, add the title

    items[currentIndex] = currentTextValue;
    setCurrentText(currentTextValue);

    try {
      const valueExisted = data?.processedOutputAI.find(
        (prop) => prop[0] === key
      );
      let newProcessedOutputAI = [];
      if (valueExisted) {
        newProcessedOutputAI = data?.processedOutputAI.map((prop) => {
          if (prop[0] === key) {
            return [key, currentTextValue];
          } else {
            return prop;
          }
        });
      } else {
        newProcessedOutputAI = [
          ...data?.processedOutputAI,
          [key, currentTextValue],
        ];
      }

      console.log(newProcessedOutputAI);

      const res = await updateDocument(data._id, {
        processedOutputAI: newProcessedOutputAI,
      });

      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(currentText);

  return (
    <div style={{ marginTop: "50px" }}>
      <div className={styles.sectionTitle}>
        <span>Feedbacks</span>
        <span className={styles.studentDetails}>
          Summary, Feedback, Strength, Weakness, Errors, Revision
        </span>
      </div>
      <div className={styles.carouselContainer}>
        <button className={styles.arrowButton} onClick={prevSlide}>
          <img src={leftArrowIcon} alt="Previous" />
        </button>
        {/* <div className={styles.carouselContent}>
          <button className={styles.editButton} onClick={handleEditClick}>
            <img src={pencilIcon} alt="Edit" />
          </button>
          {isEditing ? (
            <textarea
              className={styles.editableText}
              value={currentText}
              onChange={handleTextChange}
              onBlur={handleBlur}
              autoFocus
            />
          ) : (
            <p className={styles.feedbackText}>{currentText}</p>
          )}
        </div> */}
        <div className={styles.carouselContent}>
          {isEditing ? (
            <textarea
              className={styles.editableTextFull}
              value={currentText}
              onChange={handleTextChange}
              onBlur={handleBlur}
              autoFocus
            />
          ) : (
            <div className={styles.feedbackTextFull} onClick={handleEditClick}>
              {currentText}
            </div>
          )}
        </div>
        <button className={styles.arrowButton} onClick={nextSlide}>
          <img src={rightArrowIcon} alt="Next" />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
